define("frontend/components/payments-widget/sent-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['payments-widget-header', 'sent-header'],
    classNameBindings: ['hasInstantPayouts:with-instant-payouts'],
    tagName: 'header',
    options: ['all', 'card', 'bank', 'manual'],
    instantPayoutAmount: 0,
    reactApp: inject.service(),
    headerText: computed('paymentType', function () {
      return "".concat(this.get('paymentType'), " payments");
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      $.ajax({
        url: '/api/v2/stripe_payouts/instant_payout_eligibility',
        type: 'GET',
        success: function success(data) {
          if (data.is_eligible) {
            _this.set('instantPayoutAmount', data.available);
          }
        }
      });
    },
    hasInstantPayouts: computed('instantPayoutAmount', function () {
      return this.get('instantPayoutAmount') > 0;
    }),
    actions: {
      selectOption: function selectOption(option) {
        this.set('showDropdown', false);
        this.get('selectOption')(option);
      },
      navigateToInstantPayouts: function navigateToInstantPayouts() {
        window.location = this.get('reactApp').instantPayoutsUrl;
      }
    }
  });

  _exports.default = _default;
});