define("frontend/routes/companies/getting-paid", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    flashMessage: inject.service(),
    model: function model() {
      var company = this._super.apply(this, arguments);

      var url = "".concat(_environment.default.APP.host, "/api/v2/companies/").concat(company.get('id'), "/payment_details");
      var invoiceFrequencies = this.store.findAll('invoice-frequency');

      var payment_details = _jquery.default.ajax({
        url: url,
        method: 'GET'
      });

      var invoiceFrequencyCounts = _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/companies/").concat(company.get('id'), "/invoice_frequency_counts"),
        method: 'GET'
      });

      return Ember.RSVP.hash({
        company: company,
        invoiceFrequencies: invoiceFrequencies,
        invoiceFrequencyCounts: invoiceFrequencyCounts,
        payment_details: payment_details
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      var company = model.company;
      company.set('tax_id', model.payment_details.tax_id);
      company.set('bank_account_number', model.payment_details.bank_account_number);
      company.set('bank_user_id', "".concat(model.payment_details.bank_user_id));
      company.set('bank_routing_number', model.payment_details.bank_routing_number);
      company.set('bank_account_nickname', model.payment_details.bank_account_nickname);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('editingAutoBill', false);
      controller.set('saving', false);
      controller.set('editingBankAccount', false);
      controller.set('showConfirmSwitchUserInvoiceFrequencies', false);
      controller.set('didValidate', false);
      controller.set('changingTaxId', false);
      controller.get('bankUser');
      controller.set('originalDefaultInvoiceFrequency', model.company.get('default_invoice_frequency'));
      controller.set('originalInvoiceFrequencies', model.company.get('invoice_frequencies').map(function (f) {
        return f;
      }));

      if (model.company.get('charge_service_fee')) {
        controller.set('serviceFeeDisabled', true);
      }

      model.company.notifyPropertyChange('auto_pay');

      if (model.company.get('sales_tax_policies.length') > 0) {
        model.company.get('sales_tax_policies').forEach(function (policy) {
          if (policy.get('is_default')) {
            controller.set('originalDefaultSalesTaxPolicy', policy);
          }
        });
      }
    },
    actions: {
      cancel: function cancel() {
        var model = this.controller.get('model.company');
        var invoiceFrequencies = model.get('invoice_frequencies');
        model.rollbackAttributes();
        invoiceFrequencies.forEach(function (invoiceFrequency) {
          invoiceFrequency.rollbackAttributes();
        });
        this.transitionTo('index').then(function () {
          return window.location.reload();
        });
      }
    }
  });

  _exports.default = _default;
});