define("frontend/components/required-documents-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    company: null,
    category: null,
    clientContracts: computed('company.client_contracts.[]', function () {
      var clientContracts = this.get('company.client_contracts').map(function (contract) {
        return Ember.Object.create({
          id: contract.get('id'),
          document_name: contract.get('document_name'),
          created_at: contract.get('created_at'),
          selected: false
        });
      });
      var numChoices = clientContracts.filterBy('selected', false).length;

      if (numChoices === 1) {
        clientContracts.filterBy('selected', false).firstObject.set('selected', true);
      }

      return clientContracts;
    }),
    description: computed('category', function () {
      var category = this.get('category.name').toLowerCase();
      return "Select any agreements below that'd be required for pet parents to sign before booking ".concat(category, " with you.");
    }),
    canSendDocuments: computed('clientContracts.@each.selected', function () {
      return this.get('clientContracts').isAny('selected', true);
    }),
    selectedContractIds: computed('clientContracts.@each.selected', function () {
      return this.get('clientContracts').filterBy('selected').mapBy('id');
    }),
    secondaryButtonText: computed('canSendDocuments', function () {
      var canSendDocuments = this.get('canSendDocuments');
      return canSendDocuments ? 'cancel' : 'skip';
    }),
    primaryButtonText: computed('selectedContractIds', function () {
      var count = this.get('selectedContractIds').length;
      return "require ".concat(count, " ").concat(count > 1 || count === 0 ? 'docs' : 'doc');
    })
  });

  _exports.default = _default;
});