define("frontend/templates/components/instant-payouts-eligible-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NGw8ASK6",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"override-frequency-modal\"]],{\"statements\":[[4,\"paper-form\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/modal-icons/squirrel.svg\"],null],false],[9],[0,\"\\n        \"],[7,\"header\",true],[8],[0,\"\\n          Squirrel!\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"subheader small\"],[8],[0,\"\\n        Now that we have your attention… Your bank supports instant payouts! When you qualify, you’ll be able to use this feature to access funds within minutes of when your clients pay you!\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"button-container\"],[8],[0,\"\\n        \"],[7,\"a\",true],[10,\"target\",\"_blank\"],[10,\"href\",\"https://help.petpocketbook.com/article/208-instant-payouts\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"style\",\"classNames\",\"click\"],[\"red\",\"override-all-button\",[28,\"action\",[[23,0,[]],\"learnMore\"],null]]],{\"statements\":[[0,\"            \"],[7,\"div\",true],[8],[0,\"\\n              learn more\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"style\",\"classNames\",\"click\"],[\"red-white\",\"dont-override-button\",[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[0,\"        dismiss\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/instant-payouts-eligible-modal.hbs"
    }
  });

  _exports.default = _default;
});