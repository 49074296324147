define("frontend/templates/components/document-name-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XG72aH8i",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\",\"onClose\"],[\"contract-rename-modal\",[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"  \"],[7,\"img\",true],[10,\"class\",\"contract-rename-modal--icon\"],[10,\"src\",\"assets/images/contracts/rename-icon.svg\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"contract-rename-modal--title\"],[8],[0,\"Rename document\"],[9],[0,\"\\n  \"],[1,[28,\"ppb-input\",null,[[\"value\",\"label\"],[[24,[\"document\",\"filename\"]],\"Document name\"]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"buttons\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"color\",\"onclick\"],[\"red-white\",[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"      cancel\\n\"]],\"parameters\":[]},null],[4,\"ppb-button\",null,[[\"color\",\"onclick\"],[\"red\",[28,\"action\",[[23,0,[]],\"renameDocument\"],null]]],{\"statements\":[[0,\"      update\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/document-name-modal.hbs"
    }
  });

  _exports.default = _default;
});