define("frontend/controllers/schedule/map", ["exports", "frontend/utils/appointment-searcher"], function (_exports, _appointmentSearcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer,
      run = Ember.run;

  var _default = Ember.Controller.extend({
    iframe: null,
    showRemovingAllFlash: false,
    removingAll: false,
    schedule: inject.controller(),
    serviceActions: inject.service(),
    reactApp: inject.service(),
    currentUser: inject.service(),
    store: inject.service(),
    media: inject.service(),
    date: computed.readOnly('schedule.date'),
    care_provider_id: computed.alias('schedule.care_provider_id'),
    searchValue: computed.readOnly('schedule.searchValue'),
    services: computed.alias('schedule.services'),
    staff: computed.alias('schedule.staff'),
    selectedAppointment: computed.alias('schedule.selectedAppointment'),
    showingCalendarOptions: computed.alias('schedule.showingCalendarOptions'),
    canAddEditAppointments: computed.readOnly('currentUser.user.canAddEditAppointments'),
    servicesIsEmpty: computed('services.content', 'services.content.@each.appointments', function () {
      var services = this.get('services.content');
      return !services || services.get('length') === 0 || services.isEvery('appointments.length', 0);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      window.addEventListener('message', this.receiveMessageFromReact.bind(this));
    },
    receiveMessageFromReact: function receiveMessageFromReact(event) {
      var _this = this;

      if (event.origin !== this.get('reactApp').appCommunicationUrl) {
        return;
      }

      if (event.data.type === 'reactAppReadyForMessages') {
        console.log('PetPocketbook: Ember is aware that React is ready to receive data');
        this.sendCareProviderData();
      } else if (event.data.type === 'appointmentIdToEmber') {
        var appointmentId = event.data.appointmentId;
        console.log('PetPocketbook: Ember received data from React: ', event);
        var appointment = this.store.peekRecord('appointment', appointmentId);

        if (!appointment) {
          this.store.findRecord('appointment', appointmentId).then(function (appt) {
            _this.send('clickAppointment', appt);
          });
        } else {
          this.send('clickAppointment', appointment);
        }
      }
    },
    setupIframe: function setupIframe(element) {
      var iframe = document.createElement('iframe');
      iframe.src = this.get('reactApp').scheduleMapUrl;
      iframe.style.width = '100%';
      iframe.style.height = 'calc(100vh - 90px)';
      iframe.style.border = 'none';
      element.appendChild(iframe);
      this.set('iframe', iframe);
    },
    sendCareProviderData: function sendCareProviderData() {
      var iframe = this.get('iframe');

      if (!iframe || !iframe.contentWindow) {
        return;
      } // Until additional filters are setup in ember, then if staffFilter comes in as cancellations,
      // then appointment_type should be set to cancelled and care_provider to all


      var careProviderValue = this.get('care_provider_id');
      var data = {};

      if (careProviderValue === 'cancellations') {
        data = {
          staffFilter: 'all',
          petFilter: this.get('searchValue'),
          date: this.get('date'),
          appointmentType: 'cancelled'
        };
      } else {
        data = {
          staffFilter: this.get('care_provider_id'),
          petFilter: this.get('searchValue'),
          date: this.get('date'),
          appointmentType: 'confirmed'
        };
      }

      var message = {
        type: 'scheduleFiltersToReact',
        data: data
      };
      console.log('PetPocketbook: Ember is sending data to React - ', message);
      iframe.contentWindow.postMessage(message, this.get('reactApp').appCommunicationUrl);
    },
    selectedCareProvider: computed('care_provider_id', 'staff.[]', function () {
      if (this.get('care_provider_id') === 'all') {
        return 'all';
      } else if (this.get('care_provider_id') === 'cancellations') {
        return 'cancellations';
      } else {
        var careProviderId = this.get('care_provider_id');
        return this.get('store').peekRecord('care-provider', careProviderId);
      }
    }),
    numAppointments: computed('services.@each.appointments', 'searchValue', 'care_provider_id', function () {
      var services = this.get('services');
      var petNameSearchValue = this.get('searchValue');
      var countCanceledAppointments = this.get('care_provider_id') === 'cancellations';
      return _appointmentSearcher.default.numVisitsInServices(petNameSearchValue, services, countCanceledAppointments);
    }),
    numPets: computed('services.@each.appointments', 'searchValue', 'care_provider_id', function () {
      var services = this.get('services');
      var petNameSearchValue = this.get('searchValue');
      var countCanceledAppointments = this.get('care_provider_id') === 'cancellations';
      return _appointmentSearcher.default.numPetsInServices(petNameSearchValue, services, countCanceledAppointments);
    }),
    actions: {
      didInsertIframe: function didInsertIframe(element) {
        this.setupIframe(element);
      },
      removeAll: function removeAll() {
        this.set('showRemovingAllFlash', true);
      },
      confirmRemoveAll: function confirmRemoveAll() {
        var _this2 = this;

        var serviceActions = this.get('serviceActions');

        if (this.get('removingAll')) {
          return;
        }

        this.set('showRemovingAllFlash', false);
        this.set('removingAll', true);
        serviceActions.removeAllFor(this.get('date'), this.get('care_provider_id')).then(function () {
          _this2.notifyPropertyChange('services');
        }).finally(function () {
          window.location.reload();

          _this2.set('removingAll', false);
        });
      },
      denyRemoveAll: function denyRemoveAll() {
        this.set('showRemovingAllFlash', false);
      },
      clickAppointment: function clickAppointment(appointment) {
        if (appointment.get('appointment_detail.isLoaded')) {
          this.store.peekAll('appointment_detail').filterBy('id', appointment.get('appointment_detail.id'))[0].reload();
        }

        this.set('selectedAppointment', appointment);
        this.set('showingCalendarOptions', false);
        this.get('schedule').send('showSidePanel');
      },
      transitionToNew: function transitionToNew() {
        var selectedHouseholdId = null;
        var filter = '';
        var date = this.get('date');
        var backRoute = 'schedule.map';
        var queryParams = {
          selectedHouseholdId: selectedHouseholdId,
          filter: filter,
          date: date,
          backRoute: backRoute
        };
        this.transitionToRoute('add-appointment.index', {
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});