define("frontend/components/appointment-requests-list/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['request-header'],
    classNameBindings: ['selectedRequestType', 'hideMobileHeader'],
    tagName: 'header',
    selectedRequestType: '',
    requestTypeOptions: [],
    showDropdown: false,
    media: inject.service(),
    didInsertElement: function didInsertElement() {
      this.get('setHeaderChild')(this);
    },
    hideMobileHeader: computed('media.isMobile', 'requestTypesAreGrouped', function () {
      return this.get('media.isMobile') && this.get('requestTypesAreGrouped');
    }),
    headerTitle: computed('selectedRequestType', function () {
      if (this.get('selectedRequestType') == 'all') {
        return 'pending';
      }

      return this.get('selectedRequestType');
    }),
    showFilterOptions: function showFilterOptions() {
      this.toggleProperty('showDropdown');
    },
    actions: {
      selectHeaderOption: function selectHeaderOption(option) {
        this.set('showDropdown', false);
        this.get('selectOption')(option);
      }
    }
  });

  _exports.default = _default;
});