define("frontend/components/document-list/signature-request-list-item", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Component$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend((_Ember$Component$exte = {
    tagName: 'tr',
    classNameBindings: ['swipedLeft', 'isPending:pending'],
    media: inject.service(),
    reactApp: inject.service(),
    flashMessage: inject.service(),
    householdID: null,
    showMenu: false,
    isHovering: false,
    isPending: computed('signatureRequest.status', function () {
      return this.get('signatureRequest.status') === 'pending';
    }),
    isSigned: computed('signatureRequest.status', function () {
      return this.get('signatureRequest.status') === 'signed';
    }),
    isArchived: computed('signatureRequest.status', function () {
      return this.get('signatureRequest.status') === 'archived';
    }),
    isViewed: computed('signatureRequest.status', function () {
      return this.get('signatureRequest.status') === 'viewed';
    })
  }, _defineProperty(_Ember$Component$exte, "isSigned", computed('signatureRequest.status', function () {
    var status = this.get('signatureRequest.status');
    return status === 'viewed' || status === 'agreed' || status === 'declined' || status === 'signed';
  })), _defineProperty(_Ember$Component$exte, "handleMouseEnter", function handleMouseEnter() {
    this.set('isHovering', true);
  }), _defineProperty(_Ember$Component$exte, "handleMouseLeave", function handleMouseLeave() {
    this.set('isHovering', false);
  }), _defineProperty(_Ember$Component$exte, "didInsertElement", function didInsertElement() {
    this._super.apply(this, arguments);

    var offClickHandler = this.get('clickedOutsideElement').bind(this);
    this.set('offClickHandler', offClickHandler);
    (0, _jquery.default)(document).on('click', offClickHandler);
    this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
    this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
  }), _defineProperty(_Ember$Component$exte, "willDestroyElement", function willDestroyElement() {
    this._super.apply(this, arguments);

    (0, _jquery.default)(document).off('click', this.get('offClickHandler'));
    this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    this.element.removeEventListener('mouseleave', this.handleMouseLeave);
  }), _defineProperty(_Ember$Component$exte, "clickedOutsideElement", function clickedOutsideElement(event) {
    var $element = (0, _jquery.default)(this.element);
    var $target = (0, _jquery.default)(event.target);

    if (!$target.closest($element).length) {
      this.set('showMenu', false);
      this.set('isHovering', false);
    }
  }), _defineProperty(_Ember$Component$exte, "formattedDate", computed('signatureRequest.last_activity_date', function () {
    return moment.parseZone(this.get('signatureRequest.last_activity_date')).format('MMM D, YYYY');
  })), _defineProperty(_Ember$Component$exte, "lastActivityAction", computed('signatureRequest.status', function () {
    var status = this.get('signatureRequest.status');
    return status === 'pending' ? 'sent' : status;
  })), _defineProperty(_Ember$Component$exte, "actions", _defineProperty({
    toggleMenu: function toggleMenu() {
      this.toggleProperty('showMenu');
    },
    cancelRequest: function cancelRequest() {
      this.remove.apply(this, arguments);
      this.toggleProperty('showMenu');
    },
    internalSendReminder: function internalSendReminder(signatureRequest) {
      var _this = this;

      var householdID = this.get('householdID');

      _jquery.default.ajax({
        url: "/api/v2/client_contracts/".concat(signatureRequest.client_contract_id, "/send_to_client?household_id=").concat(householdID),
        method: 'POST'
      }).then(function () {
        _this.get('flashMessage').sendFlashMessage('Reminder sent successfully', 'Success');
      }).catch(function () {
        _this.get('flashMessage').sendFlashMessage("Oops! We couldn't send the reminder. Please contact support if the issue persists.", 'error');
      });
    },
    internalDestroyRequest: function internalDestroyRequest(signatureRequest) {
      this.get('cancelRequest')(signatureRequest, false);
    },
    internalArchiveRequest: function internalArchiveRequest(signatureRequest) {
      this.get('archiveSignedAreement')(signatureRequest);
    },
    internalViewDocument: function internalViewDocument() {
      console.log('signatureRequest: ', this.get('signatureRequest'));
      var signedPdfUrl = this.get('signatureRequest.signed_pdf_url');

      if (signedPdfUrl) {
        window.open(signedPdfUrl, this.get('target'));
        this.toggleProperty('showMenu');
      } else {
        var requestId = this.get('signatureRequest.id');
        window.open(this.get('reactApp').deltaBasedDocumentPrintView(requestId), '_blank');
      }
    },
    internalUnarchiveRequest: function internalUnarchiveRequest(signatureRequest) {
      this.get('unarchiveSignedAreement')(signatureRequest);
    },
    internalDestroyArchivedRequest: function internalDestroyArchivedRequest(signatureRequest) {
      this.get('destroyArchivedAreement')(signatureRequest);
    },
    viewPendingDocument: function viewPendingDocument() {
      window.open(this.get('signatureRequest.pdf_url'), this.get('target'));
      this.toggleProperty('showMenu');
    }
  }, "cancelRequest", function cancelRequest() {
    this.remove.apply(this, arguments);
    this.toggleProperty('showMenu');
  })), _Ember$Component$exte));

  _exports.default = _default;
});