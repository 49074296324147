define("frontend/router", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('routeDidChange', function () {
        var params = _this.targetState.routerJsState.queryParams;
        $.each(_this.targetState.routerJsState.params, function (path, pathParams) {
          $.each(pathParams, function (param, value) {
            params[param] = value;
          });
        });

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/tracking/pageview"),
          method: 'POST',
          data: {
            current_path: _this.currentPath,
            referral_url: _this.currentURL,
            params: params
          }
        });
      });
    }
  });
  Router.map(function () {
    this.route('index', {
      path: '/'
    });
    this.route('invalid-browser');
    this.route('microdeposits', {
      path: '/microdeposits/:emd5'
    });
    this.route('verified-bank-account', {
      path: '/verified-bank-account/:emd5'
    });
    this.route('add-bank-account', {
      path: '/add-bank-account/:emd5'
    });
    this.route('accept-company', {
      path: '/accept-company/:token'
    });
    this.route('confirm-email', {
      path: '/confirm-email/:token'
    });
    this.route('accept-staff', {
      path: '/accept-staff/:token'
    });
    this.route('token-login-redirect', {
      path: '/tkl'
    });
    this.route('terms', {
      path: 'terms-of-service'
    });
    this.route('privacy', {
      path: 'privacy-policy'
    });
    this.route('covid-letter');
    this.route('profile', function () {
      this.route('edit-about-me');
      this.route('account');
      this.route('integrations', function () {
        this.route('google-calendar');
      });
    });
    this.route('users', function () {
      this.route('login');
      this.route('forgot-password');
    });
    this.route('companies', {
      path: '/companies/:id'
    }, function () {
      this.route('getting-paid', {
        path: '/billing/getting-paid'
      });
      this.route('making-payments', {
        path: '/billing/making-payments'
      });
      this.route('edit');
      this.route('pricing');
      this.route('remove-service', {
        path: '/remove-service/:offeredServiceId'
      });
      this.route('remove-staff', {
        path: '/remove-staff/:employeeId'
      });
      this.route('paying-staff');
      this.route('category-settings', {
        path: '/category-settings/:page'
      });
      this.route('documents');
      this.route('integrations', function () {
        this.route('quickbooks');
        this.route('google-calendar');
      });
    });
    this.route('admin', function () {
      this.route('breeds');
      this.route('veterinarian-clinics');
      this.route('veterinarian-clinic-groups');
      this.route('create-company');
      this.route('import-clients');
      this.route('doctors');
      this.route('insurances');
      this.route('email-look-up');
      this.route('companies');
    });
    this.route('change-password');
    this.route('account-select');
    this.route('quickbooks-sync');
    this.route('add-appointment', function () {
      this.route('details');
      this.route('times');
    });
    this.route('edit-recurring-appointment-group', {
      path: '/edit-recurring-appointment-group/:id'
    }, function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('remove-recurring-appointment-group', {
      path: '/remove-recurring-appointment-group/:id'
    });
    this.route('edit-single-appointment', {
      path: '/edit-single-appointment'
    }, function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('schedule', function () {
      this.route('calendar');
      this.route('map');
    });
    this.route('invoices');
    this.route('invoices-summary', {
      path: '/invoices-summary/:household_id'
    }, function () {
      this.route('invoice-detail', {
        path: '/invoice-detail/:invoiceId'
      });
      this.route('payment-detail', {
        path: '/payment-detail/:paymentId'
      });
    });
    this.route('staff', function () {});
    this.route('staff-search', {
      path: '/staff/search'
    });
    this.route('add-staff', {
      path: '/staff/new'
    });
    this.route('edit-staff', {
      path: '/staff/edit/:staff_id'
    });
    this.route('add-client', function () {
      this.route('client-search');
      this.route('primary-contact');
      this.route('secondary-contact', {
        path: ':household_id/secondary-contact'
      });
      this.route('pet', {
        path: ':household_id/pet'
      });
      this.route('billing-settings', {
        path: ':household_id/billing-settings'
      });
      this.route('documents', {
        path: ':household_id/documents'
      });
    });
    this.route('edit-client', {
      path: 'edit-client/:household_id'
    }, function () {
      this.route('primary-contact');
      this.route('secondary-contact');
      this.route('remove-household');
      this.route('pet', {
        path: 'pet/:pet_id'
      });
      this.route('remove-pet', {
        path: 'remove-pet/:pet_id'
      });
      this.route('add-pet');
      this.route('add-secondary-contact');
      this.route('billing-settings');
      this.route('remove-household-user', {
        path: 'remove-household-user/:household_user_id'
      });
    });
    this.route('client-list', function () {
      this.route('index', {
        path: '/'
      });
      this.route('household', {
        path: '/:household_id'
      }, function () {
        this.route('pet', {
          path: '/pet/:pet_id'
        });
        this.route('household-user', {
          path: '/household-user/:household_user_id'
        });
        this.route('appointments');
        this.route('documents');
        this.route('messages');
        this.route('billing');
        this.route('error');
      });
      this.route('household-error');
    });
    this.route('inbox', function () {
      this.route('household', {
        path: '/:household_id'
      });
      this.route('distribution-list');
    });
    this.route('portal', function () {
      this.route('index', {
        path: '/'
      });
      this.route('profile');
      this.route('appointments');
      this.route('billing');
      this.route('notifications');
      this.route('reset-password');
      this.route('messages', function () {
        this.route('company', {
          path: '/company/:company_id'
        });
      });
      this.route('invoices-summary', function () {
        this.route('company', {
          path: '/company/:company_id'
        }, function () {
          this.route('invoice-detail', {
            path: '/invoice-detail/:invoiceId'
          });
          this.route('payment-detail', {
            path: '/payment-detail/:paymentId'
          });
        });
      });
      this.route('appointment-request', function () {
        this.route('pet-selection');
        this.route('service-selection');
        this.route('date-selection');
        this.route('time-selection');
        this.route('summary');
      });
    });
    this.route('catch-all', {
      path: '/*path'
    });
    this.route('sign-up', function () {
      this.route('success');
    });
  });
  var _default = Router;
  _exports.default = _default;
});