define("frontend/controllers/client-list/household/documents", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    hasContracts: computed.gt('model.contracts.length', 0),
    hasMultipeContracts: computed.gt('model.contracts.length', 1),
    signedContracts: computed.equal('model.household.esignature_status', 'signed'),
    contractsNotSent: computed.equal('model.household.esignature_status', 'none'),
    showContractsAlert: computed('hasContracts', 'signedContracts', function () {
      return this.get('hasContracts') && !this.get('signedContracts');
    }),
    clickedContractButton: false,
    careProviderAddedNewClient: false,
    emptyDocuments: computed('model.documents', function () {
      var documents = this.get('model.documents');
      return documents.length == 0;
    }),
    shouldSeeHouseholdContractAlert: computed('model.household.id', 'currentUser.user.households_contract_alerts_dismissed.[]', function () {
      return this.get('showContractsAlert') && this.get('currentUser.user').shouldSeeHouseholdContractAlert(parseInt(this.get('model.household.id')));
    }),
    clientContractsAvailableToSend: computed('currentUser.user.company.client_contracts.[]', 'model.signatureRequests.[]', function () {
      var signatureRequests = this.get('model.signatureRequests');

      if (signatureRequests && typeof signatureRequests.then === 'function') {
        return false;
      }

      var activeSignatureRequests = signatureRequests.filter(function (req) {
        return req.status !== 'archived';
      });
      var clientContracts = this.get('currentUser.user.company.client_contracts').map(function (contract) {
        return Ember.Object.create({
          id: contract.get('id'),
          sent: activeSignatureRequests.isAny('client_contract_id', parseInt(contract.get('id')))
        });
      });
      var numChoices = clientContracts.filterBy('sent', false).length;
      return numChoices > 0;
    }),
    docOrRequestForNotif: null,
    docOrRequestName: computed('docOrRequestForNotif', function () {
      var docOrRequestForNotif = this.get('docOrRequestForNotif');
      return (docOrRequestForNotif === null || docOrRequestForNotif === void 0 ? void 0 : docOrRequestForNotif.filename) || (docOrRequestForNotif === null || docOrRequestForNotif === void 0 ? void 0 : docOrRequestForNotif.document_name);
    }),
    notification: null,
    notifications: {
      archiveDocument: {
        confirmationAction: 'confirmArchiveUserDocument',
        messagePrefix: 'Are you sure you would like to archive',
        yesText: 'archive',
        noText: 'cancel'
      },
      permanentlyDeleteDocument: {
        confirmationAction: 'confirmRemoveDocument',
        messagePrefix: 'Are you sure you would like to permanently delete',
        yesText: 'yes',
        noText: 'no'
      },
      cancelSignatureRequest: {
        confirmationAction: 'confirmCancelRequest',
        messagePrefix: 'Are you sure you would like to cancel the signature request for',
        yesText: 'yes',
        noText: 'no'
      },
      archiveSignedSignatureRequest: {
        confirmationAction: 'confirmArchiveSignedAreement',
        messagePrefix: 'Are you sure you want to archive this signed',
        yesText: 'archive',
        noText: 'cancel'
      },
      permanentlyDeleteSignedSignatureRequest: {
        confirmationAction: 'confirmDestroyArchivedAreement',
        messagePrefix: 'Are you sure you would like to permanently delete the signed version of',
        yesText: 'yes',
        noText: 'no'
      }
    },
    actions: {
      callEndpoint: function callEndpoint(url, method) {
        var _this = this;

        var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        var callback = arguments.length > 3 ? arguments[3] : undefined;

        _jquery.default.ajax({
          url: url,
          method: method,
          data: data
        }).then(function () {
          _this.send('reload');
        }).done(function () {
          _this.send('clearNotification');

          if (callback) callback();
        });
      },
      toggleSendDocumentsModal: function toggleSendDocumentsModal() {
        if (this.get('careProviderAddedNewClient')) {
          this.send('togglePropAndDirectToBillingPage');
        } else {
          this.toggleProperty('showSendDocumentsModal');
        }
      },
      toggleCareProviderAddedNewClient: function toggleCareProviderAddedNewClient() {
        this.toggleProperty('careProviderAddedNewClient');
      },
      togglePropAndDirectToBillingPage: function togglePropAndDirectToBillingPage() {
        this.toggleProperty('careProviderAddedNewClient');
        var household_id = this.get('model.household.id');
        window.location.href = "/client-list/".concat(household_id, "/billing");
      },
      clearNotification: function clearNotification() {
        this.set('docOrRequestForNotif', null);
        this.set('notification', null);
      },
      archiveUserDocument: function archiveUserDocument(userDocument) {
        this.set('docOrRequestForNotif', userDocument);
        this.set('notification', this.get('notifications.archiveDocument'));
      },
      confirmArchiveUserDocument: function confirmArchiveUserDocument() {
        var household = this.get('model.household');
        var userDocument = this.get('docOrRequestForNotif');
        var url = "/api/v2/households/".concat(household.get('id'), "/user_documents/").concat(userDocument.id, "/archive");
        this.send('callEndpoint', url, 'POST');
      },
      confirmRemoveDocument: function confirmRemoveDocument() {
        var household = this.get('model.household');
        var doc = this.get('docOrRequestForNotif');
        var url = "/api/v2/households/".concat(household.get('id'), "/user_documents/").concat(doc.id);
        this.send('callEndpoint', url, 'DELETE');
      },
      removeDocument: function removeDocument(doc) {
        this.set('docOrRequestForNotif', doc);
        this.set('notification', this.get('notifications.permanentlyDeleteDocument'));
      },
      cancelRequest: function cancelRequest(signatureRequest) {
        this.set('docOrRequestForNotif', signatureRequest);
        this.set('notification', this.get('notifications.cancelSignatureRequest'));
      },
      confirmCancelRequest: function confirmCancelRequest() {
        var signatureRequest = this.get('docOrRequestForNotif');
        var url = '/api/v2/client_contracts/cancel_request';
        this.send('callEndpoint', url, 'POST', {
          signature_request_id: signatureRequest.id
        });
      },
      archiveSignedAreement: function archiveSignedAreement(signatureRequest) {
        this.set('docOrRequestForNotif', signatureRequest);
        this.set('notification', this.get('notifications.archiveSignedSignatureRequest'));
      },
      confirmArchiveSignedAreement: function confirmArchiveSignedAreement() {
        var signatureRequest = this.get('docOrRequestForNotif');
        var url = '/api/v2/client_contracts/archive_request';
        this.send('callEndpoint', url, 'POST', {
          signature_request_id: signatureRequest.id
        });
      },
      destroyArchivedAreement: function destroyArchivedAreement(signatureRequest) {
        this.set('docOrRequestForNotif', signatureRequest);
        this.set('notification', this.get('notifications.permanentlyDeleteSignedSignatureRequest'));
      },
      confirmDestroyArchivedAreement: function confirmDestroyArchivedAreement() {
        var signatureRequest = this.get('docOrRequestForNotif');
        var url = '/api/v2/client_contracts/cancel_request';
        this.send('callEndpoint', url, 'POST', {
          signature_request_id: signatureRequest.id
        });
      },
      activateUploadFilePicker: function activateUploadFilePicker() {
        $('input').click();
      },
      uploadDocument: function uploadDocument(doc, fileName) {
        var _this2 = this;

        var household = this.get('model.household');
        var url = "/api/v2/households/".concat(household.get('id'), "/user_documents");
        this.set('uploading', true);
        this.send('callEndpoint', url, 'POST', {
          user_document: {
            document: doc,
            filename: fileName
          }
        }, function () {
          return _this2.set('uploading', false);
        });
      },
      sendContracts: function sendContracts() {
        var _this3 = this;

        var householdId = this.get('model.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_client_contracts",
          method: 'POST',
          data: {
            household_id: householdId,
            send_email: true
          }
        }).then(function () {
          _this3.set('justSentContracts', true);

          _this3.set('model.household.esignature_status', 'sent');

          _this3.set('clickedContractButton', true);

          _this3.set('sendingContracts', false);
        });
      },
      sendClientContracts: function sendClientContracts(clientContractIds) {
        var _this4 = this;

        var householdId = this.get('model.household.id');

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_specific_contracts_to_household",
          method: 'POST',
          data: {
            contract_ids: clientContractIds,
            household_id: householdId
          }
        }).then(function () {
          if (_this4.get('careProviderAddedNewClient')) {
            _this4.send('togglePropAndDirectToBillingPage');
          } else {
            window.location.reload();
          }
        });
      },
      sendContractReminder: function sendContractReminder() {
        var _this5 = this;

        var householdId = this.get('model.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_client_contract_reminder",
          method: 'POST',
          data: {
            household_id: householdId
          }
        }).then(function () {
          _this5.set('justSentContracts', false);

          _this5.set('clickedContractButton', true);

          _this5.set('sendingContracts', false);
        });
      },
      sendReminder: function sendReminder() {
        var _this6 = this;

        var householdId = this.get('model.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_reminder",
          method: 'POST',
          data: {
            household_id: householdId
          }
        }).then(function () {
          _this6.set('justSentContracts', false);

          _this6.set('clickedContractButton', true);

          _this6.set('sendingContracts', false);
        });
      },
      emailPDF: function emailPDF(signatureRequestId) {
        var url = '/api/v2/client_contracts/email_pdf';
        this.send('callEndpoint', url, 'POST', {
          signature_request_id: signatureRequestId
        });
      },
      dismissAgreements: function dismissAgreements() {
        var householdId = parseInt(this.get('model.household.id'));
        this.get('currentUser.user').addHouseholdIdToDismissedContracts(householdId);

        _jquery.default.ajax({
          url: '/api/v2/user_dismissed_contracts_alert',
          method: 'POST',
          data: {
            household_id: householdId
          }
        });
      },
      unarchiveSignedAreement: function unarchiveSignedAreement(signatureRequest) {
        var url = '/api/v2/client_contracts/unarchive_request';
        this.send('callEndpoint', url, 'POST', {
          signature_request_id: signatureRequest.id
        });
      },
      unarchiveUserDocument: function unarchiveUserDocument(document) {
        var household = this.get('model.household');
        var url = "/api/v2/households/".concat(household.get('id'), "/user_documents/").concat(document.id, "/unarchive");
        this.send('callEndpoint', url, 'POST');
      }
    }
  });

  _exports.default = _default;
});