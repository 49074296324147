define("frontend/components/appointments-subheader", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['appointments-subheader'],
    numAppointments: 0,
    date: null,
    selectedCareProvider: null,
    isWeekView: false,
    searching: false,
    currentUser: Ember.inject.service(),
    company: computed.alias('currentUser.user.company'),
    weekType: computed.alias('company.weekType'),
    isYou: computed('selectedCareProvider', 'currentUser.user.id', function () {
      var selectedCareProvider = this.get('selectedCareProvider');

      if ([null, undefined, 'all', 'requests'].includes(selectedCareProvider)) {
        return false;
      }

      return selectedCareProvider.id === this.get('currentUser.user.id');
    }),
    isAll: computed.equal('selectedCareProvider', 'all'),
    showingCanceled: computed.equal('selectedCareProvider', 'cancellations'),
    momentDate: computed('date', function () {
      return (0, _moment.default)(this.get('date'), 'YYYY-MM-DD');
    }),
    haveHad: computed('momentDate', 'isYou', function () {
      if (this.get('momentDate').diff((0, _moment.default)(), 'days') >= 0) {
        if (this.get('isYou')) {
          return 'have';
        } else {
          return 'has';
        }
      } else {
        return 'had';
      }
    }),
    areWere: computed('momentDate', 'numAppointments', function () {
      if (this.get('momentDate').diff((0, _moment.default)(), 'days') >= 0) {
        if (this.get('numAppointments') == 1) {
          return 'is';
        } else {
          return 'are';
        }
      } else {
        if (this.get('numAppointments') == 1) {
          return 'was';
        } else {
          return 'were';
        }
      }
    }),
    staffName: computed('selectedCareProvider.getFirstName', 'isYou', function () {
      if (this.get('isYou')) {
        return 'You';
      } else if (this.get('isAll')) {
        return 'Your team';
      } else {
        return this.get('selectedCareProvider.getFirstName');
      }
    }),
    actions: {
      removeAll: function removeAll() {
        this.removeAll();
      },
      clickSearch: function clickSearch() {
        this.get('clickSearch')();
      },
      toggleCalendarOptions: function toggleCalendarOptions() {
        if (this.get('showingCalendarOptions')) {
          this.get('hideCalendarOptions')();
        } else {
          this.get('showCalendarOptions')();
        }
      }
    }
  });

  _exports.default = _default;
});