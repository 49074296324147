define("frontend/components/document-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['document-list'],
    tagName: 'table',
    showArchivedDocs: false,
    hasArchivedDocs: computed('combinedDocuments.@each.status', function () {
      return this.get('combinedDocuments').isAny('status', 'archived');
    }),
    hideShowButtonText: computed('showArchivedDocs', function () {
      return this.get('showArchivedDocs') ? 'hide archived documents' : 'show archived documents';
    }),
    combinedDocuments: computed('documents.[]', 'signatureRequests.[]', function () {
      var documents = this.get('documents');
      var signatureRequests = this.get('signatureRequests');
      var pendingSignatureRequests = signatureRequests.filterBy('status', 'pending');
      var archivedSignatureRequests = signatureRequests.filterBy('status', 'archived');
      var archivedDocuments = documents.filterBy('status', 'archived');
      var completedNonArchivedSignatureRequests = signatureRequests.filter(function (request) {
        return request.status !== 'pending' && request.status !== 'archived';
      });
      var nonArchivedDocuments = documents.filter(function (doc) {
        return doc.status !== 'archived';
      });
      var combinedNonArchived = nonArchivedDocuments.concat(completedNonArchivedSignatureRequests);
      var sortedNonArchived = combinedNonArchived.sortBy('last_activity_date').reverse();
      var combinedArchived = archivedDocuments.concat(archivedSignatureRequests);
      var sortedArchived = combinedArchived.sortBy('last_activity_date').reverse();
      return pendingSignatureRequests.concat(sortedNonArchived).concat(sortedArchived);
    }),
    docsToShow: computed('combinedDocuments.@each.status', 'showArchivedDocs', function () {
      var combinedDocs = this.get('combinedDocuments');

      if (!this.get('showArchivedDocs')) {
        return combinedDocs.filter(function (doc) {
          return doc.status !== 'archived';
        });
      }

      return combinedDocs;
    }),
    actions: {
      remove: function remove() {
        this.remove.apply(this, arguments);
      },
      toggleArchivedDocs: function toggleArchivedDocs() {
        this.toggleProperty('showArchivedDocs');
      },
      archive: function archive(signatureRequest) {
        this.get('archiveSignedAreement')(signatureRequest);
      }
    }
  });

  _exports.default = _default;
});