define("frontend/components/instant-payouts-eligible-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      learnMore: function learnMore() {
        window.open('https://help.petpocketbook.com/article/208-instant-payouts', '_blank').focus();
        this.get('onClose')();
      },
      close: function close() {
        this.get('onClose')();
      }
    }
  });

  _exports.default = _default;
});